<template>
  <v-textarea
    v-if="filtroL.funcao == 'IN' || filtroL.funcao == 'NOT IN'"
    :label="filtroL.funcao == 'BETWEEN' ? 'Valor Inicial' : 'Valor'"
    v-model="filtroL.valor"
    rows="3"
    no-resize
    class="mb-0"
    hint="Um valor por linha"
    @input="(valor) => updateFiltroValor(filtroL, valor)"
    :loading="loading"
    :disabled="loading"
  />
  <div
    v-else-if="
      (filtroL.type == 'date' || filtroL.type == 'datetime') &&
      filtroL.funcao != '' &&
      filtroL.funcao != 'IS NULL' &&
      filtroL.funcao != 'IS NOT NULL'
    "
  >
    <v-menu
      v-if="filtroL.campo.toUpperCase() == campoDataBase.toUpperCase()"
      v-model="menus3[index]"
      :close-on-content-click="false"
      :nudge-right="100"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs3 }">
        <v-text-field
          v-model="filtroL.monthFormatted"
          type="tel"
          v-mask="'##/####'"
          :label="filtroL.funcao == 'BETWEEN' ? 'Valor Inicial' : 'Valor'"
          @blur="
            (filtroL.month = parseMonth(filtroL.monthFormatted)),
              updateFiltroValor(filtroL, filtroL.month)
          "
          v-bind="attrs3"
          v-on="on"
          :loading="loading"
          :disabled="loading"
        />
      </template>
      <v-date-picker
        v-model="filtroL.month"
        type="month"
        :landscape="false"
        @input="
          (menus3[index] = false),
            updateFiltroValor(filtroL, filtroL.month),
            (filtroL.monthFormatted = formatMonth(filtroL.month))
        "
        locale="pt-br"
      />
    </v-menu>
    <v-menu
      v-else
      v-model="menus[index]"
      :close-on-content-click="false"
      :nudge-right="100"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :disabled="
            filtroL.funcao == '' ||
            filtroL.funcao == 'IS NULL' ||
            filtroL.funcao == 'IS NOT NULL' ||
            loading
          "
          v-model="filtroL.dateFormatted"
          v-mask="'##/##/####'"
          :label="filtroL.funcao == 'BETWEEN' ? 'Valor Inicial' : 'Valor'"
          @blur="
            (filtroL.date = parseDate(filtroL.dateFormatted)),
              updateFiltroValor(filtroL, filtroL.date)
          "
          v-bind="attrs"
          v-on="on"
          :loading="loading"
        />
      </template>
      <v-date-picker
        v-model="filtroL.date"
        @input="
          (menus[index] = false),
            updateFiltroValor(filtroL, filtroL.date),
            (filtroL.dateFormatted = formatDate(filtroL.date))
        "
        locale="pt-br"
      />
    </v-menu>
    <span v-if="filtroL.funcao == 'BETWEEN'"> e </span>
    <div v-if="filtroL.funcao == 'BETWEEN'">
      <v-menu
        v-if="filtroL.campo.toUpperCase() == campoDataBase.toUpperCase()"
        v-model="menus4[index]"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs4 }">
          <v-text-field
            v-model="filtroL.monthFormatted2"
            type="tel"
            v-mask="'##/####'"
            label="Valor Final"
            @blur="
              (filtroL.month2 = parseMonth(filtroL.monthFormatted2)),
                updateFiltroValor(filtroL, filtroL.month2)
            "
            v-bind="attrs4"
            v-on="on"
            :loading="loading"
            :disabled="loading"
          />
        </template>
        <v-date-picker
          v-model="filtroL.month2"
          type="month"
          :landscape="false"
          @input="
            (menus4[index] = false),
              updateFiltroValorFinal(filtroL, filtroL.month2),
              (filtroL.monthFormatted2 = formatMonth(filtroL.month2))
          "
          locale="pt-br"
        />
      </v-menu>
      <v-menu
        v-else
        v-model="menus2[index]"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs2 }">
          <v-text-field
            v-model="filtroL.dateFormatted2"
            v-mask="'##/##/####'"
            label="Valor Final"
            @blur="
              (filtroL.date2 = parseDate(filtroL.dateFormatted2)),
                updateFiltroValorFinal(filtroL, filtroL.date2)
            "
            v-bind="attrs2"
            v-on="on"
            :loading="loading"
            :disabled="loading"
          />
        </template>
        <v-date-picker
          v-model="filtroL.date2"
          @input="
            (menus2[index] = false),
              updateFiltroValorFinal(filtroL, filtroL.date2),
              (filtroL.dateFormatted2 = formatDate(filtroL.date2))
          "
          locale="pt-br"
        />
      </v-menu>
    </div>
  </div>
  <div v-else>
    <v-text-field
      :disabled="
        filtroL.funcao == '' ||
        filtroL.funcao == 'IS NULL' ||
        filtroL.funcao == 'IS NOT NULL' ||
        loading
      "
      :label="filtroL.funcao == 'BETWEEN' ? 'Valor Inicial' : 'Valor'"
      v-model="filtroL.valor"
      @input="(valor) => updateFiltroValor(filtroL, valor)"
      :loading="loading"
    />
    <span v-if="filtroL.funcao == 'BETWEEN'"> e </span>
    <v-text-field
      v-if="filtroL.funcao == 'BETWEEN'"
      label="Valor Final"
      v-model="filtroL.valorFinal"
      @input="(valorFinal) => updateFiltroValorFinal(filtroL, valorFinal)"
      :loading="loading"
      :disabled="loading"
    />
  </div>
</template>

<script>
import dateFormatterMixins from '@/mixins/dateFormatterMixins';

export default {
  mixins: [dateFormatterMixins],

  props: {
    index: {
      type: Number,
      required: true
    },
    filtro: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    menus: [false],
    menus2: [false],
    menus3: [false],
    menus4: [false],
    campoDataBase: 'mes_dados'
  }),

  computed: {
    filtroL: {
      get() {
        return this.filtro;
      },
      set(newValue) {
        this.$emit('update:filtro', newValue);
      }
    }
  },

  methods: {
    updateFiltroValor(filtro, valor) {
      if (filtro.campo.toUpperCase() == this.campoDataBase.toUpperCase()) {
        if (valor && valor.length <= 7) filtro.valor = `${valor}-01`;
      } else {
        filtro.valor = valor;
      }

      this.render();
    },

    updateFiltroValorFinal(filtro, valorFinal) {
      if (filtro.campo.toUpperCase() == this.campoDataBase.toUpperCase()) {
        if (valorFinal && valorFinal.length <= 7)
          filtro.valorFinal = `${valorFinal}-01`;
      } else {
        filtro.valorFinal = valorFinal;
      }

      this.render();
    },

    addMenus() {
      this.menus.push(false);
      this.menus2.push(false);
      this.menus3.push(false);
      this.menus4.push(false);
    },

    removeMenus(index) {
      this.menus.splice(index, 1);
      this.menus2.splice(index, 1);
      this.menus3.splice(index, 1);
      this.menus4.splice(index, 1);
    },

    render() {
      this.$forceUpdate();
    }
  }
};
</script>
